.logo {
  height: 32px;
  margin: 16px;
  color: white;
  background: rgba(255, 255, 255, 0.2);
}

.ant-layout {
  height: 100%;
}

.site-layout-header-background {
  background: #fff;
  border-bottom: 1px solid #eff2f5;
}

.site-layout-sub-header-background {
  background: #fff;
  border-bottom: 1px solid #eff2f5;
  height: auto;
}

.site-layout-background {
  background: #fff;
}
